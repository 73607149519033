import axios from "axios";
import api_url  from '../Config';
import  storage from '../Storage';

class UserService {

    

    newCondInf(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/newCondinf`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getCondByUser(id) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/getUserCondInf`, {id : id} ,{
            headers: {
                'sb-tkn': token
            }
        });
    }

    setCondFormations(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/setCondForm`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    setCondExp(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/setCondExp`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getAll() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/getCondList`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getMyOverview() {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cd/getoverview`, { id: id }, {
            headers: {
                'sb-tkn': token
            }
        });
    }
    getMyProfile() {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cd/myprofile`, { id: id }, {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getOffres(data) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/cd/offres`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    searchOffre(data) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/cd/searchOffre`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getOffreDetails(id) {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id_cond = 0;
        if (clientData)
            id_cond = clientData.id;

        return axios.post(`${api_url}/api/cd/offreInf`, {id : id , id_cond: id_cond} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    postuler(id) {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id_cond = 0;
        if (clientData)
            id_cond = clientData.id;

        return axios.post(`${api_url}/api/cd/postuler`, {offreId : id , condidatId: id_cond} , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getMyNotif() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/getMyNotif`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    setNotifRead() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/setNotifRead`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    updateCondFormations(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/updateCondForm`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    updateCondExp(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/updateCondExp`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    updateCondInfo(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cd/updateCondInfo`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getMyDmdOffer() {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id_cond = 0;
        if (clientData)
            id_cond = clientData.id;

        return axios.post(`${api_url}/api/cd/getMyDmdOffer`, {id : id_cond} , {
            headers: {
                'sb-tkn': token
            }
        });
    }

  

 
}

export default new UserService();