import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import CondApiCall from '../../../services/api/CondApiCall';
import UserApiCall from '../../../services/api/UserApiCall';
import img_default from '../../../assets/img/img_default.png';
import { getStringOnlyDate } from '../../../utils/helpers/date.helper';
import MdpUpdate from '../../client/profile/MdpUpdate.container';
import FormationItem from '../../../ui/items/FormationItem';
import ExperienceItem from '../../../ui/items/ExperienceItem';
import EditCondFormation from './EditCondFormation.container';
import EditCondExp from './EditCondExp.container';
import EditCondProfile from './EditCondProfile.container';
import Button from 'react-bootstrap/Button'
import CvModal from '../../../ui/modals/CvModal';



const ProfileCond = () => {

    const history = useHistory();

    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [data, set_data] = useState(false);
    const [edt_pass, set_edt_pass] = useState(false);

    const [showP, setShowP] = useState(false);
    const [showEdt_frm, set_showEdt_frm] = useState(false);
    const [showEdt_exp, set_showEdt_exp] = useState(false);
    const [showEdt_inf, set_showEdt_inf] = useState(false);

    const [show_cv_modal, set_show_cv_modal] = useState(false);

    const showEditPass = () => {
        setShowP(true)
    }

    const goEdit = () => {
        history.push("profile/edt")
    }



    const loadData = () => {
        set_loading(true);
        set_error("")
        CondApiCall.getMyProfile().then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data) {
                    set_data(res.data)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const doEditPass = (data) => {
        set_loading(true);
        set_edt_pass(false)
        set_error("")
        UserApiCall.editPass(data).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    set_edt_pass(true)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    useEffect(() => {
        loadData()

        return () => {

        }

    }, [])


    return (
        <>
            <div className="row no-marg">
                <div className="col-md-12 bg-wt">
                    <div className="row no-marg pt-5 pb-3">


                        <div className="col-md-3 text-end">
                            {data && data.user && data.user.img ?
                                <img className="btn_img_pick_src position-relative" src={data.user.img} alt="" />
                                :
                                <img className="btn_img_pick_src position-relative" src={img_default} alt="" />

                            }

                        </div>

                        <div className="col-md-6">
                            <h5 className="txt-purple mb-0 mt-5">{data && data.user.nom} {data && data.user.prenom}</h5>
                            <h5 className="txt-purple mb-0 mt-0 txt-purple2">{data && data.titre}</h5>

                        </div>

                        <div className="col-md-3 position-relative">
                            <div className="position-absolute bottom-0 start-0">
                                {/* <button className="btn_round" onClick={goEdit}> <FontAwesomeIcon icon={['fas', 'pencil-alt']} /> </button> */}
                                {/* <button className="btn_round ms-3"> <FontAwesomeIcon icon={['fas', 'image']} /> </button> */}
                                <button className="btn_round ms-3" onClick={showEditPass}> <FontAwesomeIcon icon={['fas', 'key']} /> </button>
                            </div>
                        </div>


                        <div className="col-md-10 offset-md-1 dvdr-t-gray pt-4 pb-4 mt-2">

                            <div className="row no-marg">
                                <div className="col-md-12">

                                    <button className="btn_round btn_mini p-0 float-end" onClick={e => set_showEdt_inf(true)}><FontAwesomeIcon icon={['fas', 'pencil-alt']} /></button>
                                </div>

                                <div className="col-md-12 dvdr-b-gray pt-2 mt-4 pb-2">
                                    <b className="d-inline-block fnt-w5 txt-purple mb-2">Upload CV ( {data?.user?.cv ? <span className='text-success'>OK</span> : <span className='text-danger'>Pas encore</span>} ) </b>
                                    {data?.user?.cv ?
                                        <>
                                            <a className="btn btn-success w-auto float-end" href={data?.user?.cv} target="_blank">
                                                Télécharger
                                            </a>

                                            <Button variant="primary" className="w-auto float-end me-2" onClick={e => set_show_cv_modal(true)}>
                                                Modifier
                                            </Button>
                                        </>
                                        :
                                        <Button variant="success" className="w-auto float-end" onClick={e => set_show_cv_modal(true)}>
                                            Uploader
                                        </Button>
                                    }
                                </div>

                                <div className="col-md-6 dvdr-r-gray">
                                    <b className="d-block fnt-w5 txt-purple mb-2">Condidat </b>
                                    <b className="d-block fnt-w5 txt-gray">date de naissance <span className="txt_blk ps-3">{data && data.date_naissance}</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">Sex <span className="txt_blk ps-3">{data && data.sex}</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">Niveau d'étude <span className="txt_blk ps-3">{data && data.niv_etude}</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">Année(s) d'expérience <span className="txt_blk ps-3">{data && data.niv_exp} an(s)</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">Disponible depuis le <span className="txt_blk ps-3">{data && data.dispo}</span> </b>


                                </div>
                                <div className="col-md-6">
                                    <b className="d-block fnt-w5 txt-purple mb-2">Compte </b>
                                    <b className="d-block fnt-w5 txt-gray">Nom <span className="txt_blk ps-3">{data && data.user && data.user.nom}</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">Prénom <span className="txt_blk ps-3">{data && data.user && data.user.prenom}</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">E-mail <span className="txt_blk ps-3">{data && data.user && data.user.email}</span> </b>

                                </div>
                                <div className="col-md-12 dvdr-t-gray pt-2 mt-4">
                                    <b className="d-block fnt-w5 txt-purple mb-2">Présentation </b>
                                    <p className="d-block txt_blk">{data && data.resume} </p>
                                </div>
                                <div className="col-md-12 dvdr-t-gray pt-2 mt-4">
                                    <b className="d-block fnt-w5 txt-purple mb-2">Technologies et compétences </b>
                                    {data && data.skills.map((s) => <button className="btn_skill_item" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} type="button" >{s} </button>)}

                                </div>

                                <div className="col-md-6 dvdr-t-gray pt-2 mt-4">
                                    <div className="d-block">
                                        <b className="d-inline-block fnt-w5 txt-purple mb-2">Formation(s) </b>
                                        <button className="btn_round btn_mini p-0 float-end" onClick={e => set_showEdt_frm(true)}><FontAwesomeIcon icon={['fas', 'pencil-alt']} /></button>
                                    </div>
                                    {data && data.formations &&
                                        data.formations.map(f => <FormationItem data={f} />)
                                    }
                                </div>
                                <div className="col-md-6 dvdr-t-gray pt-2 mt-4">
                                    <div className="d-block">
                                        <b className="d-inline-block fnt-w5 txt-purple mb-2">Experience(s) </b>
                                        <button className="btn_round btn_mini p-0 float-end" onClick={e => set_showEdt_exp(true)}><FontAwesomeIcon icon={['fas', 'pencil-alt']} /></button>
                                    </div>
                                    {data && data.experiences &&
                                        data.experiences.map(e => <ExperienceItem data={e} />)
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <MdpUpdate showP={showP} setShowP={setShowP} onDataSubmit={doEditPass} done={edt_pass} error={error} />
            <EditCondFormation condidat={data} show={showEdt_frm} setShow={set_showEdt_frm} />
            <EditCondExp condidat={data} show={showEdt_exp} setShow={set_showEdt_exp} />
            <EditCondProfile condidat={data} show={showEdt_inf} setShow={set_showEdt_inf} />

            <CvModal show={show_cv_modal} onHide={e => set_show_cv_modal(false)} />
        </>

    );
};

export default ProfileCond;