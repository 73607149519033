import React from 'react';
import banner_client from '../../assets/img/banner_client.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CondidatsToolbox = ({ dataCount, onSearch }) => {
    return (
        <div className="col-md-12 banner_box no-padd">
            <img src={banner_client} className="banner_box_img" alt="" />
            <div className="col-md-12 banner_box_effect"></div>
            <div className="col-md-12 banner_box_content">
                <div className="col-md-12">
                    <h4 className="txt-purple2 fnt-larg"><FontAwesomeIcon icon={['fas', 'briefcase']} /> Demandes de Candidats ({dataCount}) </h4>
                </div>
                <div className="col-md-6 offset-md-3 mb-4 ">
                    <input type="text" placeholder="Recherche ...." className="txt-ctr form-control inpt_search shadow-none form-control bg-wt" />
                </div>
            </div>
        </div>
    );
};

export default CondidatsToolbox;