/* eslint-disable */
import React from 'react';
import api_url  from '../Config';
import io  from "socket.io-client";
import Storage from '../Storage';


export const SocketContext = React.createContext();

class SoketOp{
    socket = null;

    doConnect(token){
  
        this.socket = io('https://api.smartbridge.fr' , {
            path :'/api/socket.io/',
            autoConnect:false,
            auth: {
                token: token
              },
        });
        this.socket.connect()
    }
}

export const socketManager = new SoketOp()



