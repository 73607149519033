import React, { useState, useEffect } from 'react';
import banner_client from '../../../assets/img/banner_client.png'
import talent_banner from '../../../assets/img/talent_banner.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import CondidatItem from './CondidatItem';
import CondidatDetails from './CondidatDetails';
import ClientApiCall from '../../../services/api/ClientApiCall';

/* eslint-disable */

const Home = (props) => {


    const [data, set_data] = useState([]);


    const [lst_skills, set_lst_skills] = useState([]);
    const [niv_exp, set_niv_exp] = useState([]);
    const [date_dispo, set_date_dispo] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [showP, setShowP] = useState(false);
    const [selItemData, setSelItemData] = useState(null);
    const [net_err, set_net_err] = useState("");

    const [skill_value, set_skill_value] = useState("");

    const [lst_fav, set_lst_fav] = useState([]);

    const onSkillItem = (item) => {
        set_lst_skills(lst_skills.filter(elm => (elm != item)));

    }

    const onSkillAdd = (e) => {
        e.preventDefault();
        if (e.target.value != "" && lst_skills.filter(elm => (elm == e.target.value)).length == 0) {
            set_lst_skills([...lst_skills, e.target.value]);
            set_skill_value("")
        }
    }

    const onAddClick = () => {

        if (skill_value != "" && lst_skills.filter(elm => (elm == skill_value)).length == 0) {
            set_lst_skills([...lst_skills, skill_value]);
            set_skill_value("")
        }
    }

    const goSearch = () => {
        if (lst_skills.length > 0) {
            const params = {
                skills: [...lst_skills],
                niv_exp: niv_exp,
                date_dispo: date_dispo,
            }
            search(params);
        } else {
            set_data([])
        }
    }

    // const search = () => {


    //     console.log(date_dispo);
    //     console.log(niv_exp);
    //     let arr = data_src.map(el => ({ ...el, score: 0 }));
    //     console.log(arr)

    //     let vl_dispo = 0 ;
    //     let vl_exp = 0 ;
    //     let vl_sk = 0 ;

    //     if(date_dispo != "")
    //         vl_dispo = +20;

    //     if(niv_exp != "")
    //         vl_dispo = +30;

    //     if(lst_skills.length > 0)
    //         vl_sk = +0.5;


    //     //Skills score
    //     arr = arr.map(el => {
    //         let sk = 0 ;
    //         lst_skills.forEach(k => {
    //             let r = el.skills.filter(word => word.includes(k))
    //             console.log(k+" fnd"+el.skills.length);
    //             r.length > 0 && sk++
    //         });
    //         return { ...el, score: el.score + parseFloat(( +sk / +lst_skills.length *100* +vl_sk ).toFixed(0)) }
    //     });
    //     arr = arr.filter(el => +el.score > 0 )

    //     //Exp score
    //     arr = arr.map(el => (el.niv_exp == niv_exp ? { ...el, score: el.score + +vl_exp } : { ...el, score: el.score }));

    //     //Date score
    //     let dispo_d = new Date(date_dispo);
    //     arr = arr.map(el => {
    //         let d = new Date(el.dispo_date);
    //         if (dispo_d.getTime() >= d.getTime())
    //             return { ...el, score: el.score + +vl_dispo };
    //         else
    //             return { ...el, score: el.score };
    //     });




    //     set_data(arr);

    //     setIsLoading(false)

    // }

    const ondispoChange = (e) => {
        set_date_dispo([e.target.value]);
    }
    const onNivExpChange = (e) => {
        set_niv_exp([e.target.value]);
    }

    // const onDemande = () => {
    //     setIsLoading(true);
    //     setTimeout(function () {
    //         let arr = data.map(el => (el.id == selItemData.id ? { ...el, demande: true } : { ...el }));
    //         set_data(arr);
    //         setSelItemData({ ...selItemData, demande: true })
    //         setIsLoading(false)
    //     }, 1000);
    // }

    const search = (params) => {
        console.log(params)
        setIsLoading(true);
        set_net_err("")
        set_data([])
        ClientApiCall.searchmatch(params).then(
            (res) => {
                setIsLoading(false);
                console.log(res)
                if (res.data) {
                    set_data(res.data)

                }
            }, (error) => {
                setIsLoading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
            }
        );
    }


    const onDemande = (params) => {
        console.log(params)
        setIsLoading(true);
        ClientApiCall.newDmdCond(params).then(
            (res) => {
                setIsLoading(false);
                console.log(res)
                if (res.data.done) {
                    goSearch()
                }
            }, (error) => {
                setIsLoading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
                console.log(message)
            }
        );
    }

    const loadFavorisRefs = () => {

        setIsLoading(true);
        ClientApiCall.getFavorisRefs().then(
            (res) => {
                setIsLoading(false);
                console.log(res)
                if (res.data) {
                    set_lst_fav(res.data)
                }
            }, (error) => {
                setIsLoading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
                console.log(message)
            }
        );
    }

    const onFavoris = (id) => {

        setIsLoading(true);
        ClientApiCall.addToFavoris(id).then(
            (res) => {
                setIsLoading(false);
                console.log(res)
                if (res.data.done) {
                    loadFavorisRefs();
                    goSearch()
                }
            }, (error) => {
                setIsLoading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
                console.log(message)
            }
        );
    }

    const checkCondFavs = (id) => {
        let found = lst_fav.find(elem => elem.condidatId == id)

        if (found)
            return true;

        return false;
    }

    useEffect(() => {

        if (selItemData && data)
            data.forEach(el => {
                if (el.id == selItemData.id)
                    setSelItemData(el);
            });
    }, [data])


    useEffect(() => {
        goSearch()
    }, [lst_skills])

    useEffect(() => {
        loadFavorisRefs()
    }, [])



    return (

        <>
            <div className="row no-marg  ht-inh ">
                <div className="col-md-12 banner_box no-padd">
                    <img src={banner_client} className="banner_box_img" alt="" />
                    <div className="col-md-12 banner_box_effect"></div>
                    <div className="col-md-12 banner_box_content" style={{ top: "3px" }}>
                        <div className="row pt-2 txt-ctr">
                            <div className="form-group col-md-3">
                                <label className="no-marg small-label txt_blk">Année(s) d'éxperience</label>

                                <Form.Control onChange={onNivExpChange} size="sm" as="select" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }}>
                                    <option value="">Choisissez votre Ann …</option>
                                    <option value="<1"> moins d'un d'an</option>
                                    <option value="1">1 an(s)</option>
                                    <option value="2">2 an(s)</option>
                                    <option value="3">3 an(s)</option>
                                    <option value="4">4 an(s)</option>
                                    <option value="5">5 an(s)</option>
                                    <option value="6">6 an(s)</option>
                                    <option value="7">7 an(s)</option>
                                    <option value="8">8 an(s)</option>
                                    <option value="9">9 an(s)</option>
                                    <option value="10">10 an(s)</option>
                                    <option value=">10">plus que 10 an(s)</option>
                                </Form.Control>

                            </div>
                            <div className="col-md-3 form-group">
                                <label className="no-marg small-label txt_blk">Disponibilité</label>
                                <input onChange={ondispoChange} type="date" size="sm" className="form-control form-control-sm" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} />

                            </div>
                            <div className="col-md-3 form-group">
                                <label className="no-marg small-label txt_blk">Technologies</label>
                                <input type="text" placeholder="Tapez les compétence ..." value={skill_value} onChange={e => set_skill_value(e.target.value)} className="form-control form-control-sm" onKeyPress={(e) => { e.key === 'Enter' && onSkillAdd(e); }} style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} />
                                <button className="btn btn-primary" type="button" onClick={onAddClick} style={{ margin: "0px", marginTop: "-31px", borderRadius: "0px", height: "31px", width: "31px", float: "right", boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)", padding: "0px" }} >
                                    <FontAwesomeIcon icon={['fas', 'plus']} />
                                </button>
                            </div>
                            <div className="col-md-3 form-group">
                                <button type="button" className="btn btn-primary btn-login mt-4" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} onClick={goSearch}>Confirmer</button>
                            </div>
                            <div className="col-md-12  p-2">
                                {lst_skills.map((s) => <button className="btn_skill_item" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} type="button" onClick={e => onSkillItem(s)}>{s} <span><FontAwesomeIcon icon={['fas', 'times']} /></span></button>)}
                            </div>
                        </div>
                    </div>




                </div>

                <div className="row bg-wt" style={{ position: "absolute", top: "120px", margin: "0px" }}>

                    <div className="col-md-12">
              
                        {net_err && <p className="text-danger pl-4 fnt-w4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {net_err}</p>}

                    </div>
                    {/* <div class="alert alert-secondary pt-1 pb-1 mt-2" role="alert">
                        <b>Astuce :</b> Vous pouvez cliquer sur le bouton <b><FontAwesomeIcon icon={['fas', 'plus-square']} /></b> ou sur <b>Enter</b> pour ajouter des technologies
                    </div> */}

                    {isLoading ?
                        <div class="col-md-12 p-4 txt-ctr">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        data.map((s) => <CondidatItem data={s} showP={showP} setShowP={setShowP} selItemData={selItemData} setSelItemData={setSelItemData} skills_set={lst_skills} />)
                    }

                    {lst_skills.length == 0 && niv_exp.length == 0 && date_dispo.length == 0 &&
                        <div className="col-md-12 text-center">
                            <img src={talent_banner} width="300" />
                        </div>
                    }

                </div>

            </div>



            <CondidatDetails showP={showP} setShowP={setShowP} selItemData={selItemData} setSelItemData={setSelItemData} onDemande={onDemande} onFavoris={onFavoris} checkCondFavs={checkCondFavs} />

        </>
    )

}

export default Home;