import axios from "axios";
import api_url  from '../Config';
import  storage from '../Storage';

class UserService {

    

    getAllClient() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/adm/getAllClient`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    getAllCond() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/adm/getAllCond`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    getAllDmd() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/adm/getAllDmd`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    validateUser(id) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/validate`, {id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    invalidateUser(id) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/invalidate`, {id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    banUser(id) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/ban`, {id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    unBanUser(id) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/unban`, {id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getOffres(data) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/adm/getOffres`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    searchOffre(data) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/adm/searchOffre`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getOffreDetails(id) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/adm/getOffreInfo`, {id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    closeDmdSuccess(data) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/adm/closeDmdOk`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    closeDmdFail(data) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/adm/closeDmdFail`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getMissions() {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/adm/getMissions`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    updateMission(data) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/adm/updateMission`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    delMission(id) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/adm/delMission`, {id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    changeMissionState(data) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/adm/changeMission`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }


    getMyNotif() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/adm/getMyNotif`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    setNotifRead() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/adm/setNotifRead`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    setCondTjm(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/adm/setCondTjm`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }



    addContactus(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/adm/contactus/new`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getContactus() {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/adm/contactus/list`,  {
            headers: {
                'sb-tkn': token
            }
        });
    }

    updateOffre(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/adm/editoffre`, data ,  {
            headers: {
                'sb-tkn': token
            }
        });
    }

   

  

 
}

export default new UserService();