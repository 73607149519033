import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = (props) => {
    const itemClicked = () => {
        props.setSelItemData(props.data);
        props.setShowP(true);
    }

    return (

        <>
            <div className="col-md-3 p-4">
                <div className="row cond_item p-2" >
                    <div className="col-md-12 txt-ctr">
                        <img src={props.data.user.img} className="user_img cond_item_img" alt="" />
                    </div>

                    <div className="col-md-12 txt-ctr mt-3 txt-purple2" style={{ height: "50px", overflow: "hidden" }} data-toggle="tooltip" title={props.data.titre} >

                        <b >{props.data.titre}</b>

                    </div>
                    <div className="col-md-12 txt-ctr mt-1 cond_item_t2">
                        <b><FontAwesomeIcon icon={['fas', 'graduation-cap']} /> <span>{props.data.niv_etude}</span> -  </b>
                        <b className="pl-2 d-inline-block"><FontAwesomeIcon icon={['fas', 'flask']} /> <span>{props.data.niv_exp} an(s)</span> </b>
                        <b className="pl-2 d-block txt-gray fnt-sm fnt-w4"> dispo le {props.data.dispo ? props.data.dispo : '-'}</b>
                        <b className="cond_item_txt_good">{props.data.tjm} €</b>
                        <b className="d-block txt-gray fnt-smlr fnt-w5" style={{ marginTop: "-10px" }}>TJM</b>

                    </div>

                    <div className="col-md-12 txt-ctr mt-3 dvdr-t-gray" >
                        <b className="cond_item_txt_good">{(+props.data.c1 * 30 + +((+props.data.c2 / +props.skills_set.length) * 0.5 * 100) + (+props.data.c3 * 20)).toFixed(0)}%</b>
                        <b className="d-block txt-gray fnt-smlr fnt-w5" style={{ marginTop: "-10px" }}>match
                        {props.data.demande &&
                                <b className="fnt-sm" style={{ color: "#13b08c" }}> Demande envoyé <FontAwesomeIcon icon={['fas', 'check-circle']} /> <span></span></b>

                            }</b>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-primary btn-login mt-4" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} onClick={itemClicked}>voir plus</button>

                        </div>
                    </div>

                </div>

            </div>

        </>
    )

}

export default Home;